import React, { useState, useEffect } from 'react';
import { TextField, Typography, Button, Stack, Link, Box, ToggleButton, ToggleButtonGroup, Divider, Fab, IconButton, Pagination, FormHelperText, Select, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router";
import parser from 'html-react-parser';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Getuserinfo from "../components/Getuserinfo";
import Getusername from "../components/Getusername";
import GetImage from "../components/GetImage";
import GetSignedFileUrl from "../components/GetSignedFileUrl";
import Texteditor from '../components/Texteditor';
import { green, blue, red, yellow } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import sortArray from 'sort-array';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';


const blogquery = gql`
  query blog {
    getblogs {
      id
      idnumber
      blogtitle
      blogdescription
    }
  }
`;

const blogselquery = gql`
  query blogs {
    getpostscats(accessgroups: ["staff","faculty"], posttype: ["blog"]) {
     id
     title
     posttext
     author
     authoremail
     otherauthors
     postimage {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     tags
     posttype
     blog
     accessgroups
     response {
      responderemail
      responsetext
      responsedate
     }
     position
     createdAt
     archive
    } 
  }
`;

const mutatequery = gql`
  mutation edittheblog($id: ID, $blogtitle: String, $blogdescription: String) {
    updateblog(id: $id, blogtitle: $blogtitle, blogdescription: $blogdescription) {
      blogdescription
      blogtitle
    }
  }`
;

const mutatequeryadd = gql`
  mutation addblog($idnumber: Int, $blogtitle: String, $blogdescription: String) {
    newblog(idnumber: $idnumber, blogtitle: $blogtitle, blogdescription: $blogdescription) {
      idnumber
      blogtitle
      blogdescription
    }
  }
`;

const deletequery = gql`
  mutation removeblog($id: ID) {
    deleteblog (id: $id)
  }
`;

const userquery = gql`
  query Userbyid($idnumber: Int) {
   getuser(idnumber: $idnumber) {
    firstname
    avatar
    email
    id
    idnumber
    lastlogin
    lastname
    orgunit
  }
 }
`;


function Topicalblogs() {
  let idnumber = sessionStorage.getItem("otherinfo")*1;
  //let stags = sessionStorage.getItem("seltags");
  //console.log(idnumber)

  const { data, loading, error } = useQuery(blogquery);
  //const { data, loading, error } = useQuery(blogquery, { variables: { idnumber } });
  const [ edittheblog, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: blogquery, variables: { idnumber } }],});
  const [ addblog, { data3, loading3, error3 } ] = useMutation(mutatequeryadd, { refetchQueries: [{ query: blogquery, variables: { idnumber } }],});
  const [ removeblog, { data4, loading4, error4 } ] = useMutation(deletequery, { refetchQueries: [{ query: blogquery, variables: { idnumber } }],});
  const blogselq = useQuery(blogselquery);
  const [ rows, setRows ] = useState(null);
  const [ rowsblogs, setRowsblogs ] = useState(null);
  const [ rowsblogsfilter, setRowsblogsfilter ] = useState(null);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ showedit, setShowedit ] = useState([false,false,false,false,false,false,false]);
  const [ gotolink, setGoToLink] = useState("/topicalblogs");
  const [ redraw, setRedraw ] = useState(1);
  const [ showNew, setShowNew ] = useState(false);
  const [ pageSize, setPageSize ] = useState(3);
  const [ pagination, setPagination ] = useState({ count: 0, page: 1, from: 0, to: pageSize });
  const [ paginationlabel, setPaginationLabel ] = useState("");
  const [ pagelabelset, setPageLabelSet ] = useState(false);
  const [ blogIDtb, setBlogIDtb ] = useState("General");
  const [ ranchecksession, setRanCheckSession ] = useState(false);
  const userinfo = useSelector((state) => state.userinfo.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      //setRows(data.getblogs);
      setRows(data.getblogs.filter((rowf) => rowf.blogtitle!='None'));
      //setRows(sortArray([...data.getuserblogs], { by: ['createdAt'], order: 'desc'}))
      setDataLoaded(true);
      //setRowsblogsfilter(sortArray([...data.getuserblogs], { by: ['createdAt'], order: 'desc'}))
      //console.log(data.getuserblogs);
      //console.log(blogselq.data.getpostscats)
      //setRowsblogs(blogselq.data.getpostscats);
    }
  }, [data]);

  useEffect(() => {
    //console.log("Karen");
    if (blogselq.data) {
      setRowsblogs(blogselq.data.getpostscats);
      //setRowsblogsfilter(rowsblogs.filter((rowf) => rowf.blog===blogtitle));
      //let postsfromblogs = blogselq.data.getpostscats.filter((rowf) => rowf.blog!=null)
      //setRowsblogs(sortArray([...postsfromblogs], { by: ['createdAt'], order: 'desc'}))
      //setRowsblogsfilter(sortArray([...postsfromblogs], { by: ['createdAt'], order: 'desc'}))
   }
  }, [blogselq.data]);

  // this runs on a return to the getblogs page after viewing a single blog
  // use the session values to restore the most recent search
  // set ranchecksession value to true to trigger a rerun of findblogs later
  const checksession = () => {
    if (sessionStorage.getItem("returntoviewblogstb")==='true') {
      pagination.to = parseInt(sessionStorage.getItem("paginationtotb"));
      pagination.from = parseInt(sessionStorage.getItem("paginationfromtb"));
      pagination.page = parseInt(sessionStorage.getItem("pagetb"))
      setPageSize(parseInt(sessionStorage.getItem("pageSizetb")));
      //setBlogTitletb(sessionStorage.getItem("blogtitletb"));
      sessionStorage.removeItem("returntoviewblogstb");
      sessionStorage.removeItem("paginationtotb");
      sessionStorage.removeItem("paginationfromtb");
      sessionStorage.removeItem("pagetb");
      sessionStorage.removeItem("pageSizetb");
      setRanCheckSession(true);
      //console.log("pagination=", pagination);
    }
  }

  useEffect(() => {
    //console.log("rowsblogs=", rowsblogs);
    if (rowsblogs) {
      let blogfilter = rowsblogs.filter((rowf) => rowf.blog!=='66de072ffcc8fe62b1a9253b');
      //setRowsblogsfilter(rowsblogs.filter((rowf) => rowf.blog!=null));
      setRowsblogsfilter(sortArray([...blogfilter], { by: ['createdAt'], order: 'desc'}))
      //setRanCheckSession(false);
      checksession();
    }
  }, [rowsblogs]);

  useEffect(() => {
    console.log("rowsblogsfilter=", rowsblogsfilter);
    if (rowsblogsfilter && pagelabelset) getpagelabel();
  }, [rowsblogsfilter]);

  useEffect(() => {
    if (userinfo.length==0) {
      navigate("/", { replace: true})
    } else {
      //console.log("userinfo=", userinfo)
    }
  }, []);

  useEffect(() => {
    console.log("pagination=", pagination)
    if (rowsblogsfilter) {
      getpagelabel();
      setPageLabelSet(true);
    }
  },[pagination.from, pagination.to, pageSize]);

  useEffect(() => {
    console.log("ranchecksession=", ranchecksession)
    if (ranchecksession) {
      setRowsblogsfilter(rowsblogs.filter((rowf) => rowf.blog===blogIDtb));
    } else {
      setRanCheckSession(false);
    }
  }, [ranchecksession]);  

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    return <p>Ruh roh! {error.message}</p>;
  }

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const id = e.target["id"].value;
    const blogtitle = e.target["blogtitle"].value
    const blogdescription = e.target["blogdescription"].value
    const index = e.target["index"].value;

    console.log("id=", id, "blogtitle=", blogtitle, "blogdescription=", blogdescription);
    edittheblog({  variables: {id, blogtitle, blogdescription} });
    editblog(index);
  }

  const handleSubmitNew = (e) => {
    e.preventDefault();
    const blogtitle = e.target["blogtitle"].value
    const blogdescription = e.target["blogdescription"].value

    console.log("idnumber=", idnumber, "blogtitle=", blogtitle, "blogdescription=", blogdescription);
    addblog({  variables: {idnumber, blogtitle, blogdescription} });
    setShowNew(!showNew);
  }

  function setsessionitems(id) {
    sessionStorage.setItem('blogtoview', id);
    sessionStorage.setItem('paginationtotb', pagination.to);
    sessionStorage.setItem('paginationfromtb', pagination.from);
    sessionStorage.setItem('pagetb', pagination.page);
    sessionStorage.setItem('pageSizetb', pageSize);
    sessionStorage.setItem('blogIDtb', blogIDtb);
    sessionStorage.setItem('returntoviewblogstb', "true");    
  }

  const getpagelabel = () => {
    if (rowsblogsfilter) {
      let displayfrom = pagination.from+1;
      let displayto = pagination.to;
      if (displayto > rowsblogsfilter.length) displayto = rowsblogsfilter.length;
      //console.log("Blogs " + displayfrom + " to " + displayto +  " out of " + rows.length);
      setPaginationLabel("Blogs " + displayfrom + " to " + displayto +  " out of " + rows.length);
    }    
  }

  const setpagechange = (page, ps) => {
    const from = (page - 1) * ps;
    const to = (page - 1) * ps + ps;

    setPagination({...pagination, page: page, from: from, to: to })    
    console.log("pagination");
  }

  const handlePageChange = (e, page) => {
    setpagechange(page, pageSize);
  }

  const handlePageSize = (e, value) => {
    console.log("e.target.value=", e.target.value);
    setPageSize(e.target.value);
    setpagechange(pagination.page, e.target.value);
  }

  function editblog(id) {
    let newarray = showedit;
    newarray[id] = !newarray[id];
    setShowedit(newarray);
    setRedraw(redraw+1);
  }

  function deleteblog(id) {
    removeblog({  variables: {id} });    
    setRedraw(redraw+1);
  }

  const addblogform = () => {
    setShowNew(!showNew);
  }  

  function viewblogposts(blogid) {
    console.log("blogid=", blogid)
    setBlogIDtb(blogid);
    setRowsblogsfilter(rowsblogs.filter((rowf) => rowf.blog===blogid));
  }

  // navigate to the page that will show a particular blog post
  // add the id of the blog post to session storage so that the view blog page knows which one
  // also store the current find blog selection for later return
  function viewblogpost(id) {
    setsessionitems(id);
    navigate('/viewpost', { replace: true})
  }

  function editblogpost(id) {
    setsessionitems(id);
    navigate('/editblogpost', { replace: true})
  }

  function deleteblogpost(id) {
    let archive = "Y";
    editpostarchive({  variables: { id, archive } });
    //findblogs();
    //setRows(rows.filter((rowf) => rowf.id!=id));
    setRanCheckSession(true);
  }

  // show only the first 325 characters of a blog post
  const getWordStr = (mytext) => {
    let newvalue = mytext;
    if (newvalue.length>=325) {
      newvalue = newvalue.toString().substring(0,325) + ".....";
    }
    return newvalue;
  }


  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '600px', margin: 2 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/getposts">View Posts</ToggleButton>
          <ToggleButton value="/addpost">Add Post</ToggleButton>
          <ToggleButton value="/topicalblogs">Blogs</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h4" sx={{ml: 10}}>Blog Posts</Typography>
      </Stack>

      { dataloaded && showedit && (

      <Stack direction="row" sx={{margin: 2}}>
        <Stack key="blogstart">

          <Box sx={{ width: 420, maxHeight: 500, overflow: 'auto', textOverflow: 'ellipsis', bgcolor: '#EAFAF1' }}>

          <Typography variant="h5" sx={{m: 2}}>Blogs</Typography>

          {rows.map((f, index) => (

            <Box key={"box"+f.id}>

            { !showedit[index] ? (
            <>
            <Stack direction="row" sx={{mt: 2}}>
              <Link component="button" variant="h6" onClick={() => viewblogposts(f.id) } sx={{ml: 2}}>{f.blogtitle}</Link>
              <IconButton name="editblog" sx={{ color: yellow[700], width: 20, height: 20, ml: 2 }} onClick={() => editblog(index) }><EditIcon /></IconButton>
              <IconButton name="deleteblog" sx={{ color: red[500], width: 20, height: 20, ml: 2 }} onClick={() => deleteblog(f.id) }><DeleteIcon /></IconButton>
            </Stack>
            <Stack direction="column">
              <Box sx={{ fontSize: 12, width: 300, ml: 2 }}><Getusername idnumber={f.idnumber} preface="by " /></Box>
              <Typography sx={{ fontSize: 14, width: 300, ml: 2 }}>{f.blogdescription}</Typography>
            </Stack><br/>

            </>

            ) : (

            <>
            <Stack direction="column">
              <br/>
              <form onSubmit={handleSubmit}>
                <Stack direction="row">
                  <IconButton type='submit' sx={{ width: 30, height: 30, color: green[500] }}><SaveIcon/></IconButton>
                  <IconButton name="cancel" sx={{ width: 30, height: 30 }} onClick={() => editblog(index)}><CancelIcon /></IconButton>
                </Stack><br/>
                <TextField name={"blogtitle"} key={"blogtitle"+f.id} defaultValue={f.blogtitle} type={'text'} label="Blog Title" sx={{ width: 300 }}></TextField><br/><br/>         
                <TextField name={"blogdescription"} key={"blogdescription"+f.id} defaultValue={f.blogdescription} type={'text'} multiline minRows={3} label="Blog Description" sx={{ width: 300 }}></TextField><br/>           
                <TextField name={"id"} key={"id"+f.id} defaultValue={f.id} type={'text'} sx={{ display: 'none' }}></TextField>
                <TextField name={"index"} key={"index"+index} defaultValue={index} type={'text'} sx={{ display: 'none' }}></TextField>
              </form>
            </Stack><br/>
            </>
            )}

            </Box>

          ))}

          </Box>

          { showNew ? (

          <>
          <Stack direction="column">
            <br/>
            <form onSubmit={handleSubmitNew}>
              <Stack direction="row">
                <IconButton type='submit' sx={{ width: 30, height: 30, color: green[500] }}><SaveIcon/></IconButton>
                <IconButton name="cancel" sx={{ width: 30, height: 30 }} onClick={addblogform}><CancelIcon /></IconButton>
              </Stack><br/>
              <TextField name={"blogtitle"} key={"blogtitlenew"} type={'text'} label="Blog Title" sx={{ width: 300 }}></TextField><br/><br/>         
              <TextField name={"blogdescription"} key={"blogdescriptionnew"} type={'text'} multiline minRows={3} label="Blog Description" sx={{ width: 300 }}></TextField><br/>           
            </form>
          </Stack><br/>
          </>

          ) : (

          <Fab color="primary" variant="extended" aria-label="add" sx={{width: 200, mt: 2}} onClick={addblogform}>
            <AddIcon />Create a Blog
          </Fab>

          )}


        </Stack>
        <Stack sx={{ml: 5}}>

        { rowsblogsfilter && rowsblogsfilter.length>0 && (

        <Grid container direction="column" align='left' width="575px" sx={{ml: 7}}>

        {rowsblogsfilter.slice(pagination.from,pagination.to).map((post, index) => (
          <Grid item key={post.id} align="left">
            <Stack direction="row">
              <Link component="button" variant="h6" onClick={() => viewblogpost(post.id) }>{post.title}</Link>
              { (post.authoremail===userinfo.email || userinfo.email==='michael.walker@collegeunbound.edu') && (
                <>
                  <IconButton name="editblog" sx={{ color: yellow[700], width: 20, height: 20, ml: 2 }} onClick={() => editblogpost(post.id) }><EditIcon /></IconButton>
                  <IconButton name="deleteblog" sx={{ color: red[500], width: 20, height: 20, ml: 2 }} onClick={() => deleteblogpost(post.id) }><DeleteIcon /></IconButton>
                </>
              )}
            </Stack>
            <Stack spacing={5} direction="row">
              <Typography sx={{ width: 420 }}>{parser(getWordStr(post.posttext))}</Typography>
              { post.postimage.length>0 && (
              <Box>
                {post.postimage.map((f, index) => (
                  <>
                  <GetImage filelink={f.filelink} filename={f.filename} width="200"/>
                  </>
                ))}
              </Box>
              )}
            </Stack>
            <Typography sx={{ fontSize: 14}}>{"Tags: " + post.tags.join(" / ")}</Typography>
            <Typography sx={{ fontSize: 14}}>{ "Position: " + post.position }</Typography>
            <Typography sx={{ fontSize: 14}}>{ "Posted: " + moment(parseFloat(post.createdAt)).format('MM/DD/YY') }</Typography><br/>
            <Getuserinfo email={post.authoremail} first="avatar" /><br/><br/>
          </Grid>
        ))}

          <Grid item align="left">

            <Box sx={{ fontSize: 14, ml: 3, mb: 2 }}>{paginationlabel}</Box>


            <Stack direction="row">
              <Pagination count={Math.ceil(rowsblogsfilter.length/pageSize)} page={pagination.page} onChange={handlePageChange} color="primary" />
              <FormHelperText sx={{ fontSize: 14, ml: 3, mr: 2 }}>Page Size</FormHelperText>
              <Select value={pageSize} onChange={handlePageSize} label="Page Size" sx={{height: 24}} >
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </Stack>

          </Grid>


        </Grid>

        )}
          
        </Stack>
      </Stack>


      )}
    
    </div>
  );


}
                                                                     
export default Topicalblogs;


