import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Slider, Box, Stack } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { useDispatch } from 'react-redux';
import userSlice, { setorgunit, setotherinfo } from './userSlice';
import userOrgunitSlice, { setuserorgunit } from './userOrgunitSlice';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Header from './Header'
import Newposts from './Newposts';
import Getstories from '../stories/Getstories';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const userquery = gql`
  query Userbyemail($email: String) {
   getuserbyemail(email: $email) {
    firstname
    avatar
    email
    id
    idnumber
    lastlogin
    lastname
    orgunit
  }
 }
`;


const Getorgunit = (props) => {
  const [ email, setEmail ] = useState(null);
  const { data:data2, loading, error } = useQuery(userquery, { variables: { email } });
  const userinfo = useSelector((state) => state.userinfo);
  const [ rows, setRows ] = useState(null);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ seluserinfo, setSelUserInfo] = useState(null);
  const [ otherinfo, setOtherinfo] = useState(null);
  const [ orgunit, setOrgunit] = useState(null);
  //const [ pathname, setPathname] = useState(null);
  const dispatch = useDispatch();

  //setPathname(sessionStorage.getItem("pathname"));
  //let pagedirect = localStorage.getItem("pagedirect");
  //if (pagedirect.length===0) pagedirect = pagedirectprop
  //console.log("pagedirect=", pagedirect);
  const navigate = useNavigate();
  //if (pagedirect.length>1) navigate(pagedirect, { replace: true});
  //console.log("data2=", data2);

  useEffect(() => {
    console.log("userinfo.user=", userinfo.user);
    if (userinfo.user.email!=undefined) {
      console.log("userinfo.user.email=", userinfo.user.email);
      setEmail(userinfo.user.email);
    }
  }, [userinfo]);

  useEffect(() => {
    if (data2 && data2.getuserbyemail) {
      setDataLoaded(true);
      //setSelUserInfo(data2.getuserbyemail);
      console.log(data2.getuserbyemail);
      //let orgunit = data.getuserbyemail.orgunit;
      setOrgunit(data2.getuserbyemail.orgunit);
      setOtherinfo(data2.getuserbyemail.idnumber);
      //dispatch(setuserorgunit(orgunit));
      //console.log("orgunit=", orgunit);
    }
  }, [data2]);

  useEffect(() => {
    if (email!=undefined) {
     console.log("email=", email);
    }
  }, [email]);

  useEffect(() => {
    if (orgunit) {
     console.log("orgunit=", orgunit);
     //sessionStorage.setItem('orgunit', orgunit);
     dispatch(setorgunit(orgunit));
    }
  }, [orgunit]);

  useEffect(() => {
    if (otherinfo) {
     console.log("otherinfo=", otherinfo);
     //sessionStorage.setItem('otherinfo', otherinfo);
     dispatch(setotherinfo(otherinfo));
    }
  }, [otherinfo]);

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }
  
  return (
    <>

    {/*<Newposts otherinfo={otherinfo} />*/}
    </>
  );

}

export default Getorgunit;
