import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useIdle from "./useIdleTimeout"
import { useDispatch, useSelector } from 'react-redux';
import userSlice, { setorgunit, setlogintime } from './userSlice';


const Checktimeout = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const userinfo = useSelector((state) => state.userinfo);
    const { logintime } = useSelector((state) => state.userinfo);

    const navigate = useNavigate();
    //console.log("props.loggedinhist=", props.loggedinhist);

    const handleIdle = () => {
      //console.log("props.loggedinhist=",props.loggedinhist);
      console.log("logintime=", logintime);
      localStorage.setItem("fromtimeout","N");
      if (logintime>0) {
        //console.log("props.loggedinhist=",props.loggedinhist);
        console.log("logintime=", logintime);
        setOpenModal(true);
        setIsActive(true);
      }
    }
    const { idleTimer } = useIdle({ onIdle: handleIdle, idleTime: 10 })

    const stay = () => {
      resettimer();
    }

    const resettimer = () => {
        setOpenModal(false);
        setIsActive(false);
        setSeconds(0);
        console.log("stay");
        idleTimer.reset();      
    }

    const handleLogout = () => {
        //logout();
        console.log("logout");
        resettimer();
        //let idlereset = 1;
        localStorage.setItem("lastlogin", 0);
        localStorage.setItem("fromtimeout","Y");
        dispatch(setorgunit("ZZZZZ"));
        dispatch(setlogintime(0));
        navigate("/welcome");
    }

    useEffect(() => {
      let intervalId;

      if (isActive) {
        intervalId = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds + 1);
        }, 1000);
      }

      return () => clearInterval(intervalId);
    }, [isActive]);

    const handleStart = () => {
      setIsActive(true);
    };

    const handleStop = () => {
      setIsActive(false);
    };

    const handleReset = () => {
      setIsActive(false);
      setSeconds(0);
    };

    useEffect(() => {
      if (seconds>60) {
        resettimer();
        localStorage.setItem("lastlogin", 0);
        localStorage.setItem("fromtimeout","Y");
        //sessionStorage.setItem("idlereset", idlereset);
        dispatch(setorgunit("ZZZZZ"));
        dispatch(setlogintime(0));
        navigate("/welcome");
      }
    }, [seconds]);



  return (
    <>
      <Dialog
        open={openModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Stay logged in or log out."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have been idle for 10 or more minutes.  Would you like to stay connected to the community site?  You will be logged out automatically in { 60-seconds } seconds.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>Logout</Button>
          <Button onClick={stay} autoFocus>
            Stay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Checktimeout;