import { configureStore } from '@reduxjs/toolkit'
import userSlice from './components/userSlice';
import userOrgunitSlice from './components/userOrgunitSlice';

const store = configureStore({
  reducer: {
    userinfo: userSlice,
    userorgunit: userOrgunitSlice,
  },
});

export default store;
