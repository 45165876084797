import React, { useEffect, useState } from 'react';
import GoogleLoginPage from './GoogleLoginPage';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//import Header from './Header'


const Welcome = (props) => {
  const { logintime, pagedirect } = useSelector((state) => state.userinfo);
  const lastlogin = localStorage.getItem("lastlogin");
  const fromtimeout = localStorage.getItem("fromtimeout");
    /*const user = JSON.parse(sessionStorage.getItem('user'));
  const userinfo = useSelector((state) => state.userinfo);
  const userorgunit = useSelector((state) => state.userorgunit);
  console.log("userorgunit=", userorgunit);
  const orgunit = sessionStorage.getItem("orgunit");
  const headershow = sessionStorage.getItem("headershow");*/
  const [ checkedlogin, setCheckedlogin ] = useState(0)
  
  const navigate = useNavigate();

  //console.log("headershow=", headershow);
  //console.log("userinfo=", userinfo);
  //console.log("userinfo.length=", userinfo.length);
  //const message = props.message;
  //console.log("message=", message);

  useEffect(() => {
    console.log("checkedlogin=", checkedlogin, "logintime=", logintime, "lastlogin=", lastlogin, "pagedirect=", pagedirect, "fromtimeout=", fromtimeout);
  }, [checkedlogin])

  useEffect(() => {
    console.log("logintime=", logintime);
    //run this if person has logged in, logintime is based on the current browser session,
    //while lastlogin is based on local storage
    if ((logintime>0 && Date.now()-logintime<1200000) || (lastlogin>0 && Date.now()-lastlogin<1200000)) {
      setCheckedlogin(1);
      if (pagedirect.length>0) {
        navigate(pagedirect, { replace: true});
      } else {
        navigate("/newposts", { replace: true});
      }
    } else {
      setCheckedlogin(2);
    }
  }, [logintime, pagedirect])

  return (
    <>
    { (checkedlogin===0 || fromtimeout==="Y") && (
      <GoogleLoginPage />
    )}
    </>
  );
}

export default Welcome;
