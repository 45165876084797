import React, { useState, useEffect, useContext } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import Welcome from './Welcome';

const ProtectedRoute = (props) => {
  //const { logintime } = useSelector((state) => state.userinfo);
  //const [ loggedin, setLoggedin ] = useState(false);
  //const loggedin = logintime>0 && Date.now()-logintime<600000;

  /*useEffect(() => {
    console.log("loggedin=", loggedin, "logintime=", logintime);
    setLoggedin(logintime>0 && Date.now()-logintime<600000);
  }, [logintime]);  

  useEffect(() => {
    console.log("loggedin=", loggedin, "logintime=", logintime);
  }, [loggedin]);*/


  return props.loggedinhist ? <Outlet/>:<Welcome loggedinhist={props.loggedinhist}/>;
}

export default ProtectedRoute;
