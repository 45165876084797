import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
//import Header from './Header'
//import Texteditor from './Texteditor'
//import Card from './Card'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Welcome from './components/Welcome';
import Login from './components/Login';
import Newposts from './components/Newposts';
import Newposts2 from './components/Newposts2';
import { ThemeProvider } from "@mui/material";
//import UserContext from "./UserContext";

import userSlice, { setuser, setlogintime, setpagedirect } from './components/userSlice';
import { useDispatch } from 'react-redux';

import Addblogpost from './blogs/Addblogpost';
import Getblogs from './blogs/Getblogs';
import Viewblog from "./blogs/Viewblog";
import Editblogpost from './blogs/Editblogpost';
import Yourblog from './blogs/Yourblog';
import Addpost from './blogs/Addpost';
import Addpostabb from './components/Addpostabb';
import Newpostsabb from './components/Newpostsabb';
import Getposts from './blogs/Getposts';
import Viewpost from "./blogs/Viewpost";
import Topicalblogs from './blogs/Topicalblogs';
import Viewpostdirect from "./components/Viewpostdirect";
import Foremail from "./components/Foremail";

import Addresource from './resources/Addresource';
import Getresources from './resources/Getresources';
import Editresource from "./resources/Editresource";
import Viewresource from "./resources/Viewresource";

import Addstory from './stories/Addstory';
import Getstories from './stories/Getstories';
import Viewstory from "./stories/Viewstory";
import Editstory from './stories/Editstory';

import Addboardresource from './board/Addboardresource';
import Getboardresources from './board/Getboardresources';
import Editboardresource from "./board/Editboardresource";
import Viewboardresource from "./board/Viewboardresource";

import Addnecheresource from './neche/Addnecheresource';
import Getnecheresources from './neche/Getnecheresources';
import Editnecheresource from "./neche/Editnecheresource";
import Viewnecheresource from "./neche/Viewnecheresource";

import Tickets from "./techsupport/Tickets";
import Gettickets from "./techsupport/Gettickets";
import Addticket from "./techsupport/Addticket";
import Getticketfollowup from "./techsupport/Getticketfollowup";
import Sharewithwhom from "./Sharewithwhom";

import Viewenrollmentcounts from "./data/Viewenrollmentcounts";
import Viewenrollmentcrosstabs from "./data/Viewenrollmentcrosstabs";
import Viewoutcomescounts from "./data/Viewoutcomescounts";
import Viewoutcomescrosstabs from "./data/Viewoutcomescrosstabs";
import Viewbillablecredits from "./data/Viewbillablecredits";

import Mtheme from './Mtheme';
import GoogleLoginPage from './components/GoogleLoginPage';
//import GoogleLoginPage2 from './components/GoogleLoginPage2';
import Getfaculty from './faculty/Getfaculty';
import Getfacultycards from './faculty/Getfacultycards';
import Getcomms from './ataccess/Getcomms';
import User from './User';
import Header from './components/Header';
//import StartEditButtonGrid from './StartEditButtonGrid';
import ServerSidePersistence from './ServerSidePersistence';
//import { UserInfoContext, UserInfoProvider } from './UserInfoContext';
import Testing from './blogs/Testing';
import Getorgunit from "./components/Getorgunit";
import ProtectedRoute from "./components/ProtectedRoute";
import Checktimeout from "./components/Checktimeout";


function App() {
  //const user = User();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const userinfo = useSelector((state) => state.userinfo);
  //const userorgunit = useSelector((state) => state.userorgunit);
  //const userorgunit = useSelector((store) => store.userorgunit.state)
  const orgunit = sessionStorage.getItem("orgunit");
  const pathname = window.location.pathname;
  const [ loggedin, setLoggedin ] = useState(false);
  const [ email, setEmail ] = useState(null);
  const lastlogin = localStorage.getItem("lastlogin");
  //const loggedinhist = lastlogin>0 && Date.now()-lastlogin<600000;
  const [ loggedinhist, setLoggedinhist ] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const idlereset = sessionStorage.getItem("idlereset");
  //const uc = useContext(UserContext);
  const [ logintime, setLogintime ] = useState(0);
  const [ rancheck, setRancheck ] = useState(false);
  const fromtimeout = localStorage.getItem("fromtimeout");

  //const navigate = useNavigate();
  //uc.logintime = 7;
  const dispatch = useDispatch();

  console.log("lastlogin=", lastlogin);
  console.log("userinfo=", userinfo);
  //console.log("userorgunit=", userorgunit);
  //console.log("uc.logintime=", uc.logintime);

  //console.log("idlereset", idlereset);

  const list = ['/getposts','/getresources','/getstories','/foremail','/getcomms','/getnecheresources','/addpostabb','/newpostsabb','/newposts2'];
  if ((list.some(item => item.includes(pathname)) || pathname.includes("viewpostdirect")) && (pathname.length>1)) {
    console.log("pathname=", pathname);
    console.log("list.some(item => item.includes(pathname))=", list.some(item => item.includes(pathname)))
    dispatch(setpagedirect(pathname));
    
    //localStorage.setItem('pagedirect', pathname);
  }

  /*useEffect(() => {
    console.log("lastlogin=", lastlogin)
    console.log("Date.now()-lastlogin", Date.now()-lastlogin);
    if (lastlogin>0 && Date.now()-lastlogin<10000) {
      console.log("Karen");
      //setLoggedin(1);
    }
  }, [lastlogin]);*/

  useEffect(() => {
    //console.log("userinfo.user=", userinfo.user, "userinfo.logintime=",userinfo.logintime);
    if (userinfo.user.iss!=undefined) {
      //console.log("userinfo.user.iss!=undefined=", userinfo.user.iss!=undefined);
      setLoggedin(true);
      setLogintime(userinfo.logintime)
    }
  }, [userinfo]);

  /*useEffect(() => {
    setLastlogin(localStorage.getItem("lastlogin"));
  }, []);*/

  useEffect(() => {
    //console.log("logintime=", logintime, "Date.now()=", Date.now(), "lastlogin=", lastlogin);
    setLoggedinhist((logintime>0 && Date.now()-logintime<1200000) || (lastlogin>0 && Date.now()-lastlogin<1200000));
  }, [logintime]);

  useEffect(() => {
    //console.log("loggedinhist=", loggedinhist);
    setRancheck(true);
  }, [loggedinhist]);

  useEffect(() => {
    if (rancheck && loggedinhist) {
      let usertemp = JSON.parse(localStorage.getItem("user"));
      //console.log("usertemp=", usertemp);
      dispatch(setuser(usertemp));
    }
  }, [rancheck]);

  useEffect(() => {
    setEmail(localStorage.getItem("email"));
  }, []);

  useEffect(() => {
    console.log("email=", email);
  }, [email]);

  return (
    <div>
    <ThemeProvider theme={Mtheme}>
    <Container fluid className="App">

    { rancheck && (
      <BrowserRouter>
        <Header orgunit={"Staff"}/>
        <Checktimeout loggedinhist={loggedinhist}/>
        <Getorgunit email="michael.walker@collegeunbound.edu" />

        { !loggedinhist && rancheck && fromtimeout!=='Y' && <GoogleLoginPage /> }
        <Routes>

          <Route element={<ProtectedRoute loggedinhist={loggedinhist} />}>

           <Route path="/" element={ <Newposts /> } />
           <Route path="/newposts" element={ <Newposts /> } />
           <Route path="/newposts2" element={ <Newposts2 /> } />
           <Route path="/newpostsabb" element={ <Newpostsabb /> } />
           <Route path="/getposts" element={<Getposts />} />
           <Route path="/addblogpost" element={<Addblogpost />} />
           <Route path="/editblogpost" element={<Editblogpost />} />
           <Route path="/getblogs" element={<Getblogs />} />
           <Route path="/viewblog" element={<Viewblog />} />
           <Route path="/yourblog" element={<Yourblog />} />
           <Route path="/addpost" element={<Addpost />} />
           <Route path="/addpostabb" element={<Addpostabb />} />
           <Route path="/getposts" element={<Getposts />} />
           <Route path="/viewpost" element={<Viewpost />} />
           <Route path="/viewpostdirect/:id/:posttype" element={<Viewpostdirect />} />
           <Route path="/getresources" element={<Getresources />} />
           <Route path="/foremail" element={<Foremail />} />
           <Route path="/topicalblogs" element={<Topicalblogs />} />
           <Route path="/addresource" element={<Addresource />} />
           <Route path="/editresource" element={<Editresource />} />
           <Route path="/viewresource" element={<Viewresource />} />
           <Route path="/addboardresource" element={<Addboardresource />} />
           <Route path="/getboardresources" element={<Getboardresources />} />
           <Route path="/editboardresource" element={<Editboardresource />} />
           <Route path="/viewboardresource" element={<Viewboardresource />} />
           <Route path="/addnecheresource" element={<Addnecheresource />} />
           <Route path="/getnecheresources" element={<Getnecheresources />} />
           <Route path="/editnecheresource" element={<Editnecheresource />} />
           <Route path="/viewnecheresource" element={<Viewnecheresource />} />
           <Route path="/getfaculty" element={<Getfaculty />} />
           <Route path="/getfacultycards" element={<Getfacultycards />} />
           <Route path="/getcomms" element={<Getcomms />} />
           <Route path="/tickets" element={<Tickets />} />
           <Route path="/gettickets" element={<Gettickets />} />
           <Route path="/getticketfollowup" element={<Getticketfollowup />} />
           <Route path="/addticket" element={<Addticket />} />
           <Route path="/sharewithwhom" element={<Sharewithwhom />} />
           <Route path="/viewenrollmentcounts" element={<Viewenrollmentcounts />} />
           <Route path="/viewenrollmentcrosstabs" element={<Viewenrollmentcrosstabs />} />
           <Route path="/viewoutcomescounts" element={<Viewoutcomescounts />} />
           <Route path="/viewoutcomescrosstabs" element={<Viewoutcomescrosstabs />} />
           <Route path="/viewbillablecredits" element={<Viewbillablecredits />} />
           <Route path="/addstory" element={<Addstory />} />
           <Route path="/getstories" element={<Getstories />} />
           <Route path="/viewstory" element={<Viewstory />} />
           <Route path="/editstory" element={<Editstory />} />

          </Route>


          <Route path="/welcome" element={<Welcome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/googleloginpage" element={<GoogleLoginPage />} />
          <Route path="/checktimeout" element={<Checktimeout />} />
        </Routes>
      </BrowserRouter>

      )}


    </Container>
    </ThemeProvider>

    </div>
  );
}

export default App;
