import React, { useState, useEffect } from 'react';
import { Stack, Typography, ToggleButton, ToggleButtonGroup, Box } from "@mui/material";
import * as FlexmonsterReact from "react-flexmonster";
import GetUrl from "../components/GetUrl";
import { Getdata } from './Getdata';
import outcomes from "./outcomes.json";
import { useNavigate } from 'react-router-dom';


function Viewoutcomescounts() {
  const [ gotolink, setGoToLink] = useState("/viewoutcomescounts");


  const navigate = useNavigate();


  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  return (
    <>

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '700px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/viewenrollmentcounts">Enrollment</ToggleButton>
          <ToggleButton value="/viewoutcomescounts">Outcomes</ToggleButton>
          <ToggleButton value="/viewenrollmentcrosstabs">Enrollment Crosstabs</ToggleButton>
          <ToggleButton value="/viewoutcomescrosstabs">Outcomes Crosstabs</ToggleButton>
          <ToggleButton value="/viewbillablecredits">Billable Credits</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h4" sx={{ ml: 2}}>Outcomes</Typography>
      </Stack>

      <Box sx={{ fontSize: 11, ml: 2, display:'flex', alignItems:'left' }}>For internal distribution only. Data last refreshed on 2/28/25</Box>

      <div>

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       height={500}
       width={1000}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: outcomes
         },
         "slice": {
             "rows": [
                 {
                     "uniqueName": "Degree_Start",
                     "filter": {
                         "exclude": [
                             "degree_start.[15 fall]",
                             "degree_start.[16  spring]",
                             "degree_start.[16 fall]",
                             "degree_start.[17  spring]",
                             "degree_start.[17 fall]",
                             "degree_start.[18  spring]",
                             "degree_start.[18 fall]",
                             "degree_start.[19  spring]"
                         ]
                     }
                 }
             ],
             "columns": [
                 {
                     "uniqueName": "[Measures]"
                 },
                 {
                     "uniqueName": "Grad_Ever"
                 }
             ],
             "measures": [
                 {
                     "uniqueName": "personid",
                     "aggregation": "count",
                     "caption": "Students",
                     "format": "-qiahsyu8mpy00"
                 },
                 {
                     "uniqueName": "personid",
                     "aggregation": "percentofrow",
                     "caption": "Students",
                     "format": "-qiahsyu8mpy00"
                 }
             ]
         },
         "options": {
             "grid": {
                 "title": "Persistence to Graduation as of 2/28/25",
                 "showTotals": "columns",
                 "showGrandTotals": "off"
             }
         },
         "formats": [
             {
                 "name": "-qiahsyu8mpy00",
                 "decimalPlaces": 0
             }
         ],
       }}
      />

      </div>

      <div><br/></div>

      <div>

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       height={600}
       width={1000}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: outcomes
         },
         "slice": {
             "rows": [
                 {
                     "uniqueName": "Degree_Start",
                     "filter": {
                         "exclude": [
                             "degree_start.[15 fall]",
                             "degree_start.[16  spring]",
                             "degree_start.[16 fall]",
                             "degree_start.[17  spring]",
                             "degree_start.[17 fall]",
                             "degree_start.[18  spring]",
                             "degree_start.[18 fall]",
                             "degree_start.[19  spring]"
                         ]
                     }
                 }
             ],
             "columns": [
                 {
                     "uniqueName": "[Measures]"
                 },
                 {
                     "uniqueName": "Retained_1_Year"
                 }
             ],
             "measures": [
                 {
                     "uniqueName": "personid",
                     "aggregation": "count",
                     "caption": "Students",
                     "format": "-qiahsyu8mpy00"
                 },
                 {
                     "uniqueName": "personid",
                     "aggregation": "percentofrow",
                     "caption": "Students",
                     "format": "-qiahsyu8mpy00"
                 }
             ]
         },
         "options": {
             "grid": {
                 "title": "Retained One Year",
                 "showTotals": "columns",
                 "showGrandTotals": "off"
             }
         },
         "formats": [
             {
                 "name": "-qiahsyu8mpy00",
                 "decimalPlaces": 0
             }
         ],
       }}
      />

      </div>

      <div><br/></div>      

      <div>

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       height={600}
       width={1000}
       align={"center"}
       toolbar={false}
       report={{
         dataSource: {
           data: outcomes
         },
         "slice": {
             "rows": [
                 {
                     "uniqueName": "Degree_Start",
                     "filter": {
                         "exclude": [
                             "degree_start.[15 fall]",
                             "degree_start.[16  spring]",
                             "degree_start.[16 fall]",
                             "degree_start.[17  spring]",
                             "degree_start.[17 fall]",
                             "degree_start.[18  spring]",
                             "degree_start.[18 fall]",
                             "degree_start.[19  spring]"
                         ]
                     }
                 }
             ],
             "columns": [
                 {
                     "uniqueName": "[Measures]"
                 },
                 {
                     "uniqueName": "Retained_2_Years"
                 }
             ],
             "measures": [
                 {
                     "uniqueName": "personid",
                     "aggregation": "count",
                     "caption": "Students",
                     "format": "-qiahsyu8mpy00"
                 },
                 {
                     "uniqueName": "personid",
                     "aggregation": "percentofrow",
                     "caption": "Students",
                     "format": "-qiahsyu8mpy00"
                 }
             ]
         },
         "options": {
             "grid": {
                 "title": "Retained Two Years",
                 "showTotals": "columns",
                 "showGrandTotals": "off"
             }
         },
         "formats": [
             {
                 "name": "-qiahsyu8mpy00",
                 "decimalPlaces": 0
             }
         ],
       }}
      />

      </div>

      <div><br/></div>



    </>
  );
}

export default Viewoutcomescounts;