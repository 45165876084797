import { createSlice } from '@reduxjs/toolkit'
// Slice
const userSlice = createSlice({
  name: 'userinfo',
  initialState: {
    user: [],
    logintime: 0,
    pagedirect: "",
    orgunit: "",
    otherinfo: 0,
    postadded: "N",
    addpostkey: 0,
    prevaddpostkey: 0,
  },
  reducers: {
    setuser: (state, action) => {
      state.user = action.payload;
    },
    setlogintime: (state, action) => {
      state.logintime = action.payload;
    },
    setpagedirect: (state, action) => {
      state.pagedirect = action.payload;
    },
    setorgunit: (state, action) => {
      state.orgunit = action.payload;
    },
    setotherinfo: (state, action) => {
      state.otherinfo = action.payload;
    },
    setpostadded: (state, action) => {
      state.postadded = action.payload;
    },
    setaddpostkey: (state, action) => {
      state.addpostkey = action.payload;
    },
    setprevaddpostkey: (state, action) => {
      state.prevaddpostkey = action.payload;
    },
  },
});

export const { setuser, setlogintime, setpagedirect, setorgunit, setotherinfo, setpostadded, setaddpostkey, setprevaddpostkey } = userSlice.actions;

export default userSlice.reducer;
