import React, { useState, useEffect } from 'react';
import { Typography, Stack, Link, ToggleButton, ToggleButtonGroup, Select, MenuItem, TextField, Button, Box, Pagination, IconButton, FormHelperText, Snackbar, Alert } from "@mui/material";
import AWS from 'aws-sdk';
import { useQuery, useMutation, gql } from "@apollo/client";
import parser from 'html-react-parser';
import moment from 'moment';
import Taglistabb from '../components/Taglistabb';
import Grid from '@mui/material/Grid';
import Getuserinfodate from "../components/Getuserinfodate";
import Header from '../components/Header'
import GetImage from "../components/GetImage";
import Addpostabb from '../components/Addpostabb';
//import SessionTimeout from "./SessionTimeout";
import Newpostsabb from '../components/Newpostsabb';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import userSlice, { setpostadded } from './userSlice';
import { NavLink } from "react-router-dom";
import sortArray from 'sort-array';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { yellow, red } from '@mui/material/colors';

import "../components/formatlinks.css";

function Newposts2() {
  //const [ addpostkey, setAddpostkey ] = useState(Date.now());
  const [ postadded, setPostadded ] = useState("N");
  const [ snackbar, setSnackbar] = useState(null);
 
  //const userinfo = useSelector((state) => state.userinfo);
  const { addpostkey, prevaddpostkey } = useSelector((state) => state.userinfo);

  if (addpostkey!==prevaddpostkey) {
    console.log("New key created.");
    //setSnackbar({ children: 'Your post was uploaded.', severity: 'success' });
  }

  const dispatch = useDispatch();


  return (
    <div align="left">

    <Grid container direction="row" width="1100">
      <Grid item sx={{ m: 2 }}>
          <Newpostsabb posttype="posts" />
      </Grid>
      <Grid item sx={{ ml: 2, mt: 2, mb: 2 }}>
          <Addpostabb key={addpostkey}/>
      </Grid>
      <Grid item sx={{ m: 2 }}>
          <Newpostsabb posttype="stories" />
      </Grid>
      <Grid item sx={{ m: 2 }}>
          <Newpostsabb posttype="resources" />
      </Grid>
    </Grid>    

    </div>
  );


}

export default Newposts2;