import React, { useState, useEffect } from 'react';
import { Typography, Stack, Link, Box, ToggleButton, ToggleButtonGroup, Divider, Fab, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router";
import parser from 'html-react-parser';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import Getuserinfo from "../components/Getuserinfo";
import GetImage from "../components/GetImage";
import GetSignedFileUrl from "../components/GetSignedFileUrl";
import Texteditor from '../components/Texteditor';
import { green, blue, red, yellow } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import emailjs from '@emailjs/browser';
import sortArray from 'sort-array';

const blogquery = gql`
  query blog($id: ID) {
    getpost(id: $id) {
      id
      title
      posttext
      author
      authoremail
      otherauthors
      postimage {
       filename
       fileext
       fileuuid
       filedate
       filelink
      }
      file {
        filename
        fileext
        fileuuid
        filedate
        filelink
      }
      resourcelink {
        resourcelinkname
        resourcelinkurl
      }
      tags
      posttype
      accessgroups
      response {
        responderemail
        responsetext
        responsedate
      }
      position
      createdAt
    }
 }
`;

const mutatequery = gql`
  mutation addpostresponse($id: ID, $responderemail: String, $responsetext: String, $responsedate: Float) {
    newpostresponse(id: $id, responderemail: $responderemail, responsetext: $responsetext, responsedate: $responsedate) {
      response {
        responderemail
        responsedate
        responsetext
      }
    }
  }`
;


function Viewpostdirect() {
  let { id, posttype } = useParams();
  //let id = sessionStorage.getItem("blogtoview");
  //let stags = sessionStorage.getItem("seltags");
  let fromnewpostpage = sessionStorage.getItem("fromnewpostpage");

  //673755b349f597462ecae182
  //https://community.collegeunbound.edu/viewpostdirect/673755b349f597462ecae182/post
  console.log("id=", id, "postttpe=", posttype);
  //console.log("stags=", stags)
  const { data, loading, error } = useQuery(blogquery, { variables: { id } });
  const [ addpostresponse, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: blogquery, variables: { id } }],});
  //const [ addpostresponse, { data2, loading2, error2 } ] = useMutation(mutatequery);
  const [ post, setPost ] = useState(null);
  const [ responsesorted, setResponsesorted ] = useState(null);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ blogid, setBlogId ] = useState(null);
  const [ gotolink, setGoToLink] = useState(null);
  const [ showaddresponse, setShowAddResponse] = useState(false);
  const [ posttext, setPostText] = useState();
  //const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  //const userinfo = JSON.parse(localStorage.getItem("user"));
  let userinfo = useSelector((state) => state.userinfo.user);

  const user = JSON.parse(localStorage.getItem("user"));
  if (userinfo.length===0) {
    userinfo = Object.assign({}, user);
  }
  console.log("userinfo.email=", userinfo.email);


  useEffect(() => {
    //console.log("email=", userinfo.email);
    if (data) {
      setPost(data.getpost);
      //setDataLoaded(true);
      //console.log(data.getpost);
      //console.log(sessionStorage.getItem("blogtoview"))
    }
  }, [data]);

  useEffect(() => {
    console.log("userinfo.length=", userinfo.length);
    if (userinfo.length==0) {
      //navigate("/", { replace: true})
    }
  }, []);

  useEffect(() => {
    console.log("email=", userinfo.email);
    if (post) {
      if (post.response.length>0) setResponsesorted(sortArray([...post.response], { by: ['responsedate'], order: 'desc'}));
      setDataLoaded(true);
    }
  }, [post]);

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }  
  
  function viewlink(id, posttype) {
    return '/viewpostdirect/' + id + '/' + posttype[0];
  }

  const getWordStr = (mytext) => {
    let newvalue = mytext;
    if (newvalue.length>=325) {
      newvalue = newvalue.toString().substring(0,325) + ".....";
    }
    return newvalue;
  }

  const addresponse = () => {
    let responderemail = userinfo.email;
    let responsetext = posttext;
    let responsedate = Math.floor(Date.now()/1000);
    console.log("responderemail=", responderemail, "responsetext=", responsetext, "responsedate=", responsedate);

    // must use the same variable names as in the query
    addpostresponse({  variables: { id, responderemail, responsetext, responsedate } });
    setShowAddResponse(!showaddresponse);

    let to_emails = post.authoremail + "," + responderemail;
    let textbody = "<html><head></head><body><div style='width:500px'>"
      + "<p><a href='https://community.collegeunbound.edu" + viewlink(post.id,post.posttype) + "'>" +  post.title + "</a></p>"
      + "<p>" + getWordStr(post.posttext) + "</p><p>by " + post.author + " on " + moment(parseFloat(post.createdAt)).format('MM/DD/YY') + "</p><br/>"
    
    textbody = textbody + "<p>" + getWordStr(responsetext) + "</p><p>by " + responderemail + " on " + moment.unix(parseFloat(responsedate)).format('ddd, M/D/YY, h:mm a') + "</p><br/></div></body></html>"

    if (post.response.length>0) {
      //let responsesorted = sortArray([...post.response], { by: ['responsedate'], order: 'desc'})
      console.log("responsesorted=", responsesorted);
      responsesorted.map((f, index) => {
        textbody = textbody + "<p>" + getWordStr(f.responsetext) + "</p><p>by " + f.responderemail + " on " + moment.unix(parseFloat(f.responsedate)).format('ddd, M/D/YY, h:mm a') + "</p><br/>"
        to_emails = to_emails + "," + f.responderemail
      })
    }

    console.log("textbody=", textbody);
    console.log("to_emails=", to_emails);
    sendmail(to_emails,"Response to " + post.title,textbody);

  }

  const getposttext = (data) => {
    //console.log(data);
    setPostText(data);
  }

  const sendmail = (to_email, subject, message) => {
    // initialize using your User ID saved in the .env file
    emailjs.init(process.env.REACT_APP_EMAIL_USER_ID);
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_ID, // The service ID saved in the .env file
        process.env.REACT_APP_EMAIL_TEMPLATE_ID, // The template ID also saved in the .env file
        // Start of the variables defined in the template earlier
        {
          to_email: to_email,
          subject: subject,
          greeting: "",
          message: message,
        }
        // End of the variables defined in the template earlier
      )
      .then((response) => {
        // Debug statement on the console to show the function has been executed successfully
        console.log(response);
      })
      .catch((error) => {
        // Debug statement on the console to show the error that occured
        console.log(error);
    });
  }


  return (
    <div align="left">

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '600px', ml: 2, pb: 5 }}>
          { posttype==='newpost' && (
            <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
              <ToggleButton value="/newposts">Back</ToggleButton>
            </ToggleButtonGroup>
          )}
          { posttype==='newpostsabb' && (
            <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
              <ToggleButton value="/newposts">Back</ToggleButton>
            </ToggleButtonGroup>
          )}
          { (posttype==='post' || posttype==='blog') && (
            <>
            <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
              <ToggleButton value="/getposts">View Posts</ToggleButton>
              <ToggleButton value="/addpost">Add Post</ToggleButton>
              <ToggleButton value="/topicalblogs">Blogs</ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="h4">View Post</Typography>
            </>
          )}
          { posttype==='resource' && (          
            <>
            <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
              <ToggleButton value="/getresources">View Resources</ToggleButton>
              <ToggleButton value="/addresource">Add Resource</ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="h4">Resources</Typography>
            </>
          )}
          { posttype==='story' && (
            <>
            <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
              <ToggleButton value="/getstories">View Stories</ToggleButton>
              <ToggleButton value="/addstory">Add Story</ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="h4">View Story</Typography>
            </>
          )}
          { posttype==='neche' && (          
            <>
            <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
              <ToggleButton value="/getnecheresources">View Resources</ToggleButton>
              <ToggleButton value="/addnecheresource">Add Resource</ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="h4">Resources</Typography>
            </>
          )}
      </Stack>

      { dataloaded && post && (

      <Grid container direction="column" align='left' width="1000px" sx={{ p: 5}}>
        <Grid item align="left">

          { post.postimage.length>0 && (
          <Box>
            {post.postimage.map((f, index) => (
              <>
              <GetImage filelink={f.filelink} filename={f.filename} width="450"/>
              </>
            ))}
          </Box>
          )}

          <Stack direction="row" width='900px' sx={{ justifyContent: 'space-between' }}>
            <Typography variant="h6">{post.title}</Typography>
            <Getuserinfo email={post.authoremail} first='name' />
          </Stack>
          <Typography>{parser(post.posttext)}</Typography>

          {(post.file.length>0 || post.resourcelink.length>0) && (

          <Grid container direction="column" align='left' sx={{ mb: 2}}>
            <Grid item>
              <Typography>Files:</Typography>
            </Grid>
            {post.file.map((f, index) => (
              <Grid item>
                <GetSignedFileUrl filelink={f.filelink} filename={f.filename} />
              </Grid>
            ))}
            {post.resourcelink.map((f, index) => (
              <Grid item>
                <Link href={f.resourcelinkurl} target="_blank" rel="noopener">{f.resourcelinkname}</Link>
              </Grid>
            ))}
          </Grid>

          )}

          {post.tags.length>0 && (
            <Typography>{ "Tags: " + post.tags.join(" / ") }</Typography>
          )}
          <Typography>{ "Posted: " + moment(parseFloat(post.createdAt)).format('ddd, M/D/YY, h:mm a') }</Typography><br/>

        </Grid>

        { responsesorted && responsesorted.length>0 && (
          <Grid item sx={{ mt: 2, borderTop: 1, width: 600}}>

           <Box sx={{fontWeight: 'bold', fontSize: 18}}>{post.response.length + " replies"}</Box>
           <Divider />
           {responsesorted.map((f, index) => (
            <>
             <Stack direction="row" width='600px' sx={{ justifyContent: 'space-between' }}>
               <Box sx={{fontWeight: 'bold', mt: 2}}>{moment.unix(parseFloat(f.responsedate)).format('ddd, M/D/YY, h:mm a')}</Box>
               {/*<Box sx={{fontWeight: 'bold', mt: 2}}>{new Date(f.responsedate).toString()}</Box>*/}
               <Getuserinfo sx={{fontWeight: 'bold', mt: 3}} email={f.responderemail} first='name' />
             </Stack>
             <Box>{parser(f.responsetext)}</Box>
            </>
           ))}
          </Grid>
        )}

         <Grid item>
          { !showaddresponse ? (
            <Fab color="primary" aria-label="add" sx={{ mt: 2}}>
              <AddIcon onClick={() => setShowAddResponse(!showaddresponse)} />
            </Fab>
          ) : (
            <>
            <Stack direction="row" sx={{mt: 2, ml: 2}}>
              <IconButton onClick={addresponse} sx={{ width: 30, height: 30, color: green[500] }}><SaveIcon/></IconButton>
              <IconButton name="cancel" sx={{ width: 30, height: 30 }} onClick={() => { setShowAddResponse(!showaddresponse)}}><CancelIcon /></IconButton>
            </Stack>
            <Texteditor func={getposttext} height="200px" width="550px" />
            </>
          )}
          </Grid>


        </Grid>

        )}
    
    </div>
  );


}

export default Viewpostdirect;


