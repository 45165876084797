import React, { useState, useEffect } from 'react';
import { Typography, Stack, Link, ToggleButton, ToggleButtonGroup, Select, MenuItem, TextField, Button, Box, Pagination, IconButton, FormHelperText } from "@mui/material";
import AWS from 'aws-sdk';
import { useQuery, useMutation, gql } from "@apollo/client";
import parser from 'html-react-parser';
import moment from 'moment';
import Taglistabb from '../components/Taglistabb';
import Grid from '@mui/material/Grid';
import Getuserinfodate from "../components/Getuserinfodate";
import GetSignedFileUrl from "../components/GetSignedFileUrl";
import Header from '../components/Header'
import GetImage from "../components/GetImage";
//import SessionTimeout from "./SessionTimeout";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import userSlice, { setaddpostkey, setprevaddpostkey } from './userSlice';
import { NavLink } from "react-router-dom";
import sortArray from 'sort-array';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { yellow, red } from '@mui/material/colors';
import defaultimage from '../circlelogo.png';

import "../components/formatlinks.css";

//1733029200000
//1727661536000

const blogqueryposts = gql`
  query blogs {
    getpostscatsdate(accessgroups: ["staff","faculty"], posttype: ["blog"], startdate: 1733029200000) {
     id
     title
     posttext
     author
     authoremail
     otherauthors
     postimage {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     tags
     posttype
     blog
     accessgroups
     response {
      responderemail
      responsetext
      responsedate
     }
     position
     createdAt
     archive
    } 
  }
`;

const blogquerystories = gql`
  query blogs {
    getpostscatsdate(accessgroups: ["staff","faculty"], posttype: ["story"], startdate: 1733029200000) {
     id
     title
     posttext
     author
     authoremail
     otherauthors
     postimage {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     tags
     posttype
     blog
     accessgroups
     response {
      responderemail
      responsetext
      responsedate
     }
     position
     createdAt
     archive
    } 
  }
`;


const blogqueryresources = gql`
  query blogs {
    getpostscatsdate(accessgroups: ["staff","faculty"], posttype: ["resource"], startdate: 1733029200000) {
     id
     title
     posttext
     author
     authoremail
     otherauthors
     postimage {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     tags
     posttype
     blog
     accessgroups
     response {
      responderemail
      responsetext
      responsedate
     }
     position
     createdAt
     archive
    } 
  }
`;

const deletequery = gql`
  mutation removepost($id: ID) {
    deletepost (id: $id)
  }
`;

const archivequery = gql`
  mutation editpostarchive($id: ID, $archive: String) {
    updatepostarchive(id: $id, archive: $archive) {
      id
      archive
    }
  }`
;

const blognamesquery = gql`
  query blog {
    getblogs {
      id
      idnumber
      blogtitle
      blogdescription
    }
  }
`;
const Newpostsabb = (props) => {
  let blogquery;
  let cheading;
  let gwidth;
  let twidth;
  let lwidth;
  if (props.posttype=="posts") {
    blogquery = blogqueryposts;
    cheading = "New Posts";
    gwidth = "530px";
    twidth = 375;
    lwidth = 375;
  } 
  else if (props.posttype=="stories") {
    blogquery = blogquerystories
    cheading = "New Stories";
    gwidth = "530px";
    twidth = 375;
    lwidth = 375;
  }
  else if (props.posttype=="resources") {
    blogquery = blogqueryresources
    cheading = "New Resources";
    gwidth = "520px";
    twidth = 475;
    lwidth = 400;
  }
  const { data, loading, error, refetch } = useQuery(blogquery);
  const [ removepost, { data2, loading2, error2 } ] = useMutation(deletequery, { refetchQueries: [{ query: blogquery }],});
  const [ editpostarchive, { data3, loading3, error3 } ] = useMutation(archivequery, { refetchQueries: [{ query: blogquery }],});
  const blognamesq = useQuery(blognamesquery);
  const [ rows, setRows ] = useState(null);
  const [ rowsall, setRowsAll ] = useState([]);
  const [ numrows, setNumRows ] = useState(0);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ gotolink, setGoToLink] = useState("/getposts");
  const [ seltags, setSelTags ] = useState([]);
  const [ authoremails, setAuthorEmails ] = useState([]);
  const [ authornames, setAuthorNames ] = useState([]);
  const [ selectedauthor, setSelectedAuthor ] = useState("");
  const [ clearthetags, setClearTheTags ] = useState(false);
  const [ tagstoset, setTagsToSet ] = useState([]);
  const [ tagstatus, setTagStatus ] = useState("tagsnotset");
  const [ selectedname, setSelectedName ] = useState("All Authors");
  const [ ranchecksession, setRanCheckSession ] = useState(false);
  const [ pageSize, setPageSize ] = useState(7);
  const [ pagination, setPagination ] = useState({ count: 0, page: 1, from: 0, to: pageSize });
  const [ paginationlabel, setPaginationLabel ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ sortby, setSortBy ] = useState("createdAt");
  const [ raninit, setRanInit ] = useState(false);
  const [ ranfind, setRanFind ] = useState(false);
  const [ blognames, setBlognames ] = useState([]);
  const [ showblognames, setShowblognames] = useState(false);
  const [ blogsel, setBlogsel] = useState("All Blogs");
  const [ postadded, setPostadded ] = useState("N");

  const userinfo = useSelector((state) => state.userinfo);
  const { addpostkey, prevaddpostkey } = useSelector((state) => state.userinfo);
  const user = userinfo.user;

  const navigate = useNavigate();
  sessionStorage.removeItem("fromnewpostpage");

  const dispatch = useDispatch();

  //console.log("seltags=", seltags);

  /**************************** main filters  ****************************/

  // this retrieves the blogs according to seltags or selectedname values
  // if seltags has a value filter by the tags selected
  // if no tags filter by selected name
  const findblogs = () => {
    if (dataloaded && rowsall.length>0) {
      setRows(rowsall);
    }
    setRanFind(true);
  }

  function setsessionitems(id) {
    sessionStorage.setItem('blogtoview', id);
    sessionStorage.setItem('fromnewposts2', "true");
  }

  // navigate to the page that will show a particular blog post
  // add the id of the blog post to session storage so that the view blog page knows which one
  // also store the current find blog selection for later return
  function viewblog(id) {
    setsessionitems(id);
    navigate('/viewpostdirect/' + id + '/newpostsabb', { replace: true})
  }

  function editblog(id) {
    setsessionitems(id);
    navigate('/editblogpost', { replace: true})
  }

  function deleteblog(id) {
    let archive = "Y";
    editpostarchive({  variables: { id, archive } });
    setRanCheckSession(true);
  }

  // this runs on a return to the getblogs page after viewing a single blog
  // use the session values to restore the most recent search
  // set ranchecksession value to true to trigger a rerun of findblogs later
  const checksession = () => {
    if (sessionStorage.getItem("returntoviewblogs")==='true') {
      setRanCheckSession(true);
      //console.log("pagination=", pagination);
    }
  }

  // do initial setup of rows
  // also get author names and emails
  // this is triggered whenever number of rows (numrows) changes or rows changes
  // but only set rowsall before rows have been filtered, when there are the original number of rows
  // and only update author names and emails once
  const initializerows = () => {
    //if (rows) console.log("rows", rows, " rows.length=", rows.length, " numrows=", numrows);
    if (dataloaded && !raninit) {
      //setRowsAll(rows);
      setRanInit(true);
      console.log("numrows=", numrows);
    }
  }

  /******************************** triggers ***************************/
  useEffect(() => {
    if (data) {
      //setRows(data.getpostscatsdate);
      setRows(sortArray([...data.getpostscatsdate], { by: ['createdAt'], order: 'desc'}))
      //setRowsAll(data.getpostscatsdate);
      setRowsAll(sortArray([...data.getpostscatsdate], { by: ['createdAt'], order: 'desc'}))
      setDataLoaded(true);
      console.log("setrows=", rows);
   }
  }, [data]);

  useEffect(() => {
    if (dataloaded) {
      setRows(sortArray([...rows], { by: ['createdAt'], order: 'desc'}))
      setNumRows(rows.length);
      //console.log("dataloaded-setrows=", rows)
    }
  }, [dataloaded]);

  useEffect(() => {
    initializerows();
    //console.log("rows=", rows, "ranfind=", ranfind);
  }, [rows,numrows]);

  // this will run after the initialization of rows
  useEffect(() => {
    if (rowsall && rowsall.length>0) {
      //getpagelabel();
      checksession();
    }
  }, [rowsall]);

  useEffect(() => {
    if (ranchecksession) {
      findblogs();
    } else {
      setRanCheckSession(false);
    }
  }, [ranchecksession]);

  useEffect(() => {
    if (prevaddpostkey!==addpostkey) {
      console.log("prevaddpostkey=", prevaddpostkey, "addpostkey=", addpostkey);
      dispatch(setprevaddpostkey(addpostkey));
      refetch();
    }
  }, [addpostkey]);

  /*useEffect(() => {
    if (userinfo.postadded==='Y') {
      console.log("userinfo.postadded=", userinfo.postadded);
      setPostadded("Y");
    }
  }, [userinfo]);

  useEffect(() => {
    if (postadded==='Y') {
      console.log("postadded=", postadded);
      setPostadded("N");
      refetch();
    }
  }, [postadded]);*/

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }

  /**************************************** helper functions ********************************/

  // show only the first 325 characters of a blog post
  const getWordStr = (mytext, textlen) => {
    let newvalue = mytext;
    if (newvalue.length>=textlen) {
      newvalue = newvalue.toString().substring(0,textlen) + ".....";
    }
    return newvalue;
  }

  const uv = (oldarray) => {
    const uniqueValues = [];
    oldarray.map((item) => {
      if (uniqueValues.indexOf(item) === -1) {
          uniqueValues.push(item)
      }
    });
    return uniqueValues;
  }

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  const handleNameSelect = (e, value) => {
    setSelectedName(e.target.value);
    //setTagsToSet([]);
  }

  const handleBlogSelect = (e, value) => {
    setBlogsel(e.target.value);
    //setTagsToSet([]);
  }

  const gettags = (data) => {
    setTagStatus("tagsdeselected");
    setSelTags(data);
  }

  const handleFindClick = () => {
    findblogs();
    pagination.page = 1;
  }

  // get the e-mail address associated with the selected name
  const getemail = () => {
    let email = "";
    let sr = rowsall.filter((row) => row.author===selectedname);
    console.log("sr=", sr);
    email = sr[0].authoremail;
    return email;
  }

  return (
    <div align="left">

      { dataloaded && (

        <>

        <Grid container direction="row" align='left' width={gwidth}>
          <Grid item align="center" sx={{ bgcolor: '#24bb66', color: "white", justifyContent: "space-evenly", width: "100%" }}>
            <Typography variant="h6" sx={{m:1}}>{cheading}</Typography>
          </Grid>
        </Grid>


        <Grid container direction="row" align='left' width={gwidth} sx={{overflowY: "scroll", maxHeight: "500px", border: 1, borderColor: 'grey.200'}}>

        <Grid item align="center" sx={{ width: "100%" }}>
          <Typography variant="h5" sx={{m:2}}></Typography>
        </Grid>

        {rows.map((post, index) => (
          <Grid item key={post.id} align="left" sx={{ml:1}}>
            <Stack direction="row">
              <Link component="button" variant="h4" align="left" onClick={() => viewblog(post.id) } sx={{ width: lwidth, fontSize: 16 }}>{post.title}</Link>
              { (post.authoremail===user.email || user.email==='michael.walker@collegeunbound.edu') && (
                <>
                  <IconButton name="editblog" sx={{ color: yellow[700], width: 20, height: 20, ml: 2 }} onClick={() => editblog(post.id) }><EditIcon /></IconButton>
                  <IconButton name="deleteblog" sx={{ color: red[500], width: 20, height: 20, ml: 2 }} onClick={() => deleteblog(post.id) }><DeleteIcon /></IconButton>
                </>
              )}
            </Stack>

            <Stack direction="row">
              <Stack direction="column" sx={{mr: 2}}>
                <Typography><Getuserinfodate email={post.authoremail} fsize={14} posteddate={moment(parseFloat(post.createdAt)).format('MM/DD/YY')} /></Typography>
                <Typography sx={{ width: twidth, fontSize: 14 }}>{parser(getWordStr(post.posttext, 200))}</Typography>
              </Stack>
              <Stack align="center">
              { props.posttype!=="resources" && (
              <>
              { post.postimage.length>0 ? (
              <Box>
                {post.postimage.map((f, index) => (
                  <>
                  <GetImage filelink={f.filelink} filename={f.filename} width="100"/>
                  </>
                ))}
              </Box>
              ) : (
                <img src={defaultimage} alt="CU Image" height="75" />
              )}
              </>
              )}
              </Stack>
            </Stack>

          </Grid>
        ))}

        </Grid>

        </>

      )}
    
    </div>
  );


}

export default Newpostsabb;