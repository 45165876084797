import { convertToRaw, EditorState, ContentState, convertFromHTML } from "draft-js";
import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Fragment } from "react";

const Texteditor = (props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [text, setText] = useState();

  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    let text = editorState.getCurrentContent().getPlainText("\u0001");
    setText(text);
  };

  useEffect(() => {
    props.func(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState]);

  useEffect(() => {
    if (props.setthetext && props.setthetext.length>0) {
      setText(props.setthetext);
      setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.setthetext))));
      //console.log("prop.setthetext=", props.setthetext);
    }
  }, [props.setthetext]);  


  return (
    <>

      <Editor
        editorState={editorState}
        toolbarClassName="toolbar-class"
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        onEditorStateChange={onEditorStateChange}
        wrapperStyle={{ border: 0, height: props.height, width: props.width }}
        editorStyle={{
          fontSize: 12,
        }}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history','link'],
          inline: { options: ['bold', 'italic', 'underline'] },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
        }}
      />

    </>
  );
}

export default Texteditor;
