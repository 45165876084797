import React, { useState, useEffect } from 'react';
import { Typography, TextField, Select, MenuItem, Button, Slider, Box, ToggleButton, ToggleButtonGroup, Checkbox, FormGroup, FormControlLabel, IconButton } from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
//import Container from 'react-bootstrap/Container';
import Grid from '@mui/material/Grid';
import Taglistabb from '../components/Taglistabb';
import Texteditor from '../components/Texteditor';
import Uploadfp from '../components/Uploadfp';
import Uploadfp3 from '../components/Uploadfp3';
import Sharewithwhom from '../components/Sharewithwhom';
import GetSignedFileUrl from '../components/GetSignedFileUrl';
import Fab from '@mui/material/Fab';
import sortArray from 'sort-array';
import { NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import userSlice, { setpostadded, setaddpostkey } from './userSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HttpIcon from '@mui/icons-material/Http';
import ImageIcon from '@mui/icons-material/Image';
import { yellow, red, green, blue } from '@mui/material/colors';
import {v4 as uuidv4} from 'uuid';
import "../components/formatlinks.css";
import Tooltip from '@mui/material/Tooltip';

//import Taglist from './Taglist';

const mutatequery = gql`
  mutation addpost($title: String, $posttext: String, $author: String, $authoremail: String, $otherauthors: [String], $postimage: [ImageInput], $file: [FileInput], $resourcelink: [ResourceLinkInput], $tags: [String], $posttype: [String], $blog: String, $accessgroups: [String], $position: Int) {
    newpost(title: $title, posttext: $posttext, author: $author, authoremail: $authoremail, otherauthors: $otherauthors, postimage: $postimage, file: $file, resourcelink: $resourcelink, tags: $tags, posttype: $posttype, blog: $blog, accessgroups: $accessgroups, position: $position) {
      author
      posttext
    }
  }`
;

const blogquery = gql`
  query blogs {
    getpostscats(accessgroups: ["staff","faculty","world"], posttype: ["blog"]) {
     id
     title
     author
     authoremail
     posttext
     position
     file {
      filename
      fileext
      fileuuid
      filedate
      filelink
     }
     resourcelink {
      resourcelinkname
      resourcelinkurl
     }
     accessgroups
     blog
     otherauthors
     posttype
     tags
     createdAt
  }
 }
`;

const blog2 = gql`
  query blogs2 {
   getblogs {
    blogtitle
    blogdescription
  }
}
`;

const blognamesquery = gql`
  query blog($idnumber: Int) {
    getuserblogs(idnumber: $idnumber) {
      id
      idnumber
      blogtitle
      blogdescription
    }
  }
`;

function Addpostabb() {
  const { otherinfo:idnumber } = useSelector((state) => state.userinfo);
  //let idnumber = sessionStorage.getItem("otherinfo")*1;
  const blognamesq = useQuery(blognamesquery, { variables: { idnumber } });
  const [ addpost, { data:data2, loading:loading2, error:error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: blogquery }],});
  //const [ addpost, { data2, loading2, error2 } ] = useMutation(mutatequery);
  const [ title, setTitle] = useState();
  const [ mainimage, setMainImage] = useState([]);
  const [ filenames, setFileNames] = useState([]);
  const [ addfiles, setAddFiles] = useState(false);
  const [ seltags, setSelTags ] = useState(["Weekly Reflections"]);
  const [ newtag, setNewTag] = useState();
  const [ sharearray, setShareArray] = useState();
  const [ posttext, setPostText] = useState();
  const [ position, setPosition] = useState(3);
  const activeStyle = { color: 'green' };
  const [ gotolink, setGoToLink] = useState("/addpost");
  const [ blognames, setBlognames ] = useState([]);
  const [ showblognames, setShowblognames] = useState(false);
  const [ blogsel, setBlogsel] = useState("66de072ffcc8fe62b1a9253b");
  const [ rlinks, setRLinks] = useState([]);
  const [ readytosave, setReadyToSave ] = useState(false);
  const [ dataentered, setDataEntered ] = useState({title: false, posttext: false, tags: true, accessgroups: true});
  const [ astart, setAstart ] = useState();
  const [ processfiles, setProcessFiles ] = useState();
  const [ snackbar, setSnackbar] = useState(null);
  const [isdirty, setIsDirty] = useState(false);
  const [showreflection, setShowreflection] = useState(true);
  const [showimage, setShowimage] = useState(false);
  const [showfiles, setShowfiles] = useState(false);
  const [showlinks, setShowlinks] = useState(false);
  const [ clearthetags, setClearTheTags ] = useState(false);
  const [ tagstoset, setTagsToSet ] = useState([]);

  const userinfo = useSelector((state) => state.userinfo);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleBlogSubmit = (e) => {
    e.preventDefault();
    console.log(e.target["title"].value);
  }

  const handleBlogSelect = (e, value) => {
    console.log(e.target.value);
    setBlogsel(e.target.value);
  }

  const handleTitleChange = (e, value) => {
    setTitle(e.target.value);
    if (e.target.value.length>0) setDataEntered({ ...dataentered, title: true});
    else setDataEntered({ ...dataentered, title: false});
    setIsDirty(true);
  }

  const handleChangePosition = (event, value) => {
    setPosition(value);
  };

  const getposttext = (data) => {
    //console.log("data=", data);
    //console.log("data.length=", data.length);
    setPostText(data);
    if (data && data.length>8) setDataEntered({ ...dataentered, posttext: true});
    else setDataEntered({ ...dataentered, posttext: false});
  }

  const getfilenames = (data) => {
    setFileNames(data);
  }

  const getaddstart = (data) => {
    setAstart(data);
  }

  const getprocessfiles = (data) => {
    setProcessFiles(data);
  }

  const gettags = (data) => {
    console.log("tags=", data);
    setSelTags(data);
    if (data && data.length>0) setDataEntered({ ...dataentered, tags: true});
    else setDataEntered({ ...dataentered, tags: false});
  }

  const getnewtag = (data) => {
    setNewTag(data);
  }

  const getmainimage = (data) => {
    setMainImage(data);
  }  

  const getsharearray = (data) => {
    setShareArray(data);
    if (data && data.length>0) setDataEntered({ ...dataentered, accessgroups: true});
    else setDataEntered({ ...dataentered, accessgroups: false});
  }

  const addlink = (event, value) => {
    setRLinks([...rlinks, { resourcelinkid: uuidv4(), resourcelinkname: '',resourcelinkurl:'' }]);
  };

  const deleteresource = (resourcelinkid) => {
    setRLinks(rlinks.filter((rl) => rl.resourcelinkid!==resourcelinkid));
  };  

  const handleUpdateItem = (resourcelinkid, updatedItem) => {
    // Update the item in the array
    setRLinks(prevItems => prevItems.map(item =>
      item.resourcelinkid === resourcelinkid ? updatedItem : item
    ));
  };  

  // save all of the information for the post
  const savepost = () => {
    let author = userinfo.user.name;
    let authoremail = userinfo.user.email;
    let file = filenames;
    let resourcelink = rlinks;
    let posttype = ["blog"];
    let accessgroups = ["staff"];
    let tags = seltags;
    let postimage = mainimage ;

    //localStorage.setItem('returnfromaddpost', 'Y')
    console.log("filenames=", filenames);
    console.log("tags=", tags);
    console.log("authoremail=", authoremail);

    addpost({  variables: { title, posttext, author, authoremail, postimage, file, resourcelink, tags, posttype, accessgroups, position } });
    //navigate("/newposts2", { replace: true});
    dispatch(setpostadded("Y"));
    dispatch(setaddpostkey(Date.now()));
  }

  //console.log("data3=", data3);
  useEffect(() => {
    console.log("filenames=", filenames);
  }, [filenames]);

  useEffect(() => {
    console.log("astart=", astart);
    //setReadyToSave(false);
  }, [astart]);

  useEffect(() => {
    console.log("processfiles=", processfiles);
    //setReadyToSave(true);
  }, [processfiles]);

  useEffect(() => {
    console.log(sharearray);
  }, [sharearray]);

  useEffect(() => {
    //console.log("dataentered=", dataentered);
    if (dataentered.title && dataentered.posttext && dataentered.tags && dataentered.accessgroups) setReadyToSave(true);
    else setReadyToSave(false);
  }, [dataentered]);

  useEffect(() => {
    console.log("isdirty=", isdirty)
    const handleBeforeUnload = (e) => {
      if (isdirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isdirty]);  

  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }

  return (
    <div align="left">

      <Stack direction="row" alignItems="left" sx={{ justifyContent: 'flex-start', width: '400px', ml: 2, mb: 2 }}>
        <Typography variant="h6">What are you thinking about today?</Typography>
        { !showreflection ? ( 
          <IconButton sx={{ color: green[500], width: 30, height: 30, ml: 1 }} onClick={() => setShowreflection(!showreflection) }><ExpandMoreIcon /></IconButton>
        ) : (
          <IconButton sx={{ color: green[500], width: 30, height: 30, ml: 1 }} onClick={() => setShowreflection(!showreflection) }><ExpandLessIcon /></IconButton>
        )}
      </Stack>

    <Grid container>
      <Grid item>
        { showreflection && (
          <>
          <Box sx={{justifyContent: "flex-start"}}>
            <TextField name="title" value={title} onChange={handleTitleChange} placeholder="Enter a title for your reflection." variant="standard" size="large" sx={{ ml: 2, width: 470 }}></TextField><br/>
            <Texteditor func={getposttext} height="200px" width="510px" sx={{ mt:1, mb:3 }}/><br/><br/>
          </Box>

          { showimage ? (
            <Tooltip title="Hide image upload" placement="top"><IconButton sx={{ color: green[600], width: 30, height: 30, ml: 3, mt: 2, mr: 1, mb: 1 }} onClick={() => setShowimage(!showimage)}><ImageIcon /></IconButton></Tooltip>
          ) : (
            <Tooltip title="Show image upload" placement="top"><IconButton sx={{ color: blue[100], width: 25, height: 25, ml: 3, mt: 2, mr: 1, mb: 1 }} onClick={() => setShowimage(!showimage)}><ImageIcon /></IconButton></Tooltip>
          )}

          {showfiles ? (
            <Tooltip title="Hide file upload" placement="top"><IconButton sx={{ color: green[600], width: 30, height: 30, ml: 3, mt: 2, mr: 1, mb: 1  }} onClick={() => setShowfiles(!showfiles)}><AttachFileIcon /></IconButton></Tooltip>
          ) : (
            <Tooltip title="Show file upload" placement="top"><IconButton sx={{ color: blue[100], width: 25, height: 25, ml: 3, mt: 2, mr: 1, mb: 1 }} onClick={() => setShowfiles(!showfiles)}><AttachFileIcon /></IconButton></Tooltip>
          )}

          {showlinks ? (
            <Tooltip title="Hide link options" placement="top"><IconButton sx={{ color: green[500], width: 30, height: 30, ml: 3, mt: 2, mr: 1, mb: 1 }} onClick={() => setShowlinks(!showlinks)}><HttpIcon /></IconButton></Tooltip>
          ) : (
            <Tooltip title="Show link options" placement="top"><IconButton sx={{ color: blue[100], width: 25, height: 25, ml: 3, mt: 2, mr: 1, mb: 1 }} onClick={() => setShowlinks(!showlinks)}><HttpIcon /></IconButton></Tooltip>
          )}

          {showimage && (
            <>
              <Box sx={{ ml: 3, mt: 1, fontWeight: 500 }}>Upload Image</Box>
              <Box sx={{ml: 3, mr: 1, mb: 1, width: 470 }} >
                <Uploadfp func={getmainimage}/>
              </Box>
            </>
          )}

          {showfiles && (
            <>
              <Box sx={{ ml: 3, mt: 1, fontWeight: 500 }}>Add Files</Box>
              <Box sx={{ml: 3, mr: 1, mb: 1, width: 470 }} >
                <Uploadfp3 func={getfilenames} funcaddstart={getaddstart} funcprocessfiles={getprocessfiles} />
              </Box>
            </>
          )}

          {showlinks && (
            <>
            <Box sx={{ ml: 3, mt: 1, fontWeight: 500 }}>Add Links</Box>
            <Box sx={{bgcolor: '#f2f0f0', ml: 3, mb: 2, width: 500}}>
            { rlinks.map((rl, index) => (
              <>
                 <TextField name={"linkname" + index} value={rl.resourcelinkname} onChange={(e) => handleUpdateItem(rl.resourcelinkid, { ...rl, resourcelinkname: e.target.value })} placeholder="Enter name" variant="standard" sx={{ m: 1, width: 185, fontSize: 10 }}></TextField>
                 <TextField name={"linkurl" + index} value={rl.resourcelinkurl} onChange={(e) => handleUpdateItem(rl.resourcelinkid, { ...rl, resourcelinkurl: e.target.value })} placeholder="Enter link" variant="standard" sx={{ m: 1, width: 245, fontSize: 10 }}></TextField>
                 <IconButton name={"deleteresource" + index} sx={{ color: red[500], width: 20, height: 20, ml: 1, mt: 2, mr: 1 }} onClick={() => deleteresource(rl.resourcelinkid) }><DeleteIcon /></IconButton><br/>
              </>
            ))
            }
            <IconButton sx={{ color: green[500], width: 20, height: 20, ml: 1, mt: 1, mb: 2 }} onClick={addlink}><AddCircleIcon /></IconButton><br/>
            </Box>
            </>
          )}

          <br/>

          <Button onClick={savepost} variant='contained' sx={{mt: 3, ml: 3}} disabled={!readytosave} >Save Post</Button>
          {!readytosave && (
           <Typography sx={{ fontSize: 11, width: 350, mt: 2, ml: 3 }} >You must enter a title and some text.</Typography>
          )}
          </>
        )}
      </Grid>
      <Grid item sx={{ ml: 2, mb: 2 }}>

      </Grid>

    </Grid>

    </div>
  );
}

export default Addpostabb;
