import React, { useState, useEffect } from "react";
import { TextField, Button, Autocomplete, Select, MenuItem, InputLabel, FormControl, IconButton, Typography, Chip, Stack } from "@mui/material";
import { useQuery, useMutation, gql } from "@apollo/client";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckIcon from "@mui/icons-material/Check";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { green, blue, red, yellow } from '@mui/material/colors';
import sortArray from 'sort-array';

const tagquery = gql`
  query tag {
    gettagoptionsposttype(posttype: ["neche"]) {
      id
      tagoptionname
      tagcategory
      posttype
      accessgroups
      addedby
    }
  }
`;

const mutatequery = gql`
  mutation addtag($tagoptionname: String, $tagcategory: String, $posttype: [String], $accessgroups: [String], $addedby: String) {
    newtagoption(tagoptionname: $tagoptionname, tagcategory: $tagcategory, posttype: $posttype, accessgroups: $accessgroups, addedby: $addedby) {
      tagcategory
      tagoptionname
    }
  }
`;


const Taglistabbneche = (props) => {
  const { data, loading, error } = useQuery(tagquery);
  const [ addtag, { data2, loading2, error2 } ] = useMutation(mutatequery, { refetchQueries: [{ query: tagquery }],});
  const [ rows, setRows ] = useState(null);
  const [ dataloaded, setDataLoaded ] = useState(false);
  const [ tags, setTags ] = useState([]);
  const [ tagcats, setTagCats ] = useState([]);
  const [ selcat, setSelCat ] = useState(null);
  const [ seltags, setSelTags ] = useState([]);
  const [ errorselcat, setErrorSelCat ] = useState();
  const [ ranupdate, setRanUpdate ] = useState(false);
  const [ rowssort, setRowsSort] = useState([]);

  const getcat = (option) => {
    return rows.find(row => row.tagoptionname==option).tagcategory + " | " + option;
  }

  const handleChangeSelTags = (e, value) => {
    setSelTags(e.target.value);
  }

  const uv = (oldarray, startvalue) => {
    const uniqueTags = startvalue;
    oldarray.map((item) => {
      if (uniqueTags.indexOf(item) === -1) {
          uniqueTags.push(item)
      }
    });
    return uniqueTags;
  }

  const gettags = () => {
    setTags(rows.map((row) => (row.tagoptionname)));
    setTagCats(uv(rows.map((row) => row.tagcategory),["Select a category"]));
  }

  useEffect(() => {
    if (data) {
      setRows(data.gettagoptionsposttype);
      setDataLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    if (dataloaded) {
      gettags();
    }
  }, [dataloaded]);

  useEffect(() => {
    props.functags(seltags);
  }, [seltags]);

  useEffect(() => {
    if (props.cleartags) {
      setSelTags([]);
    }
  }, [props.cleartags]);

  
  useEffect(() => {
    if (props.setthetags && props.setthetags.length>0) {
      setSelTags([...props.setthetags]);
    }
    else setSelTags([])
  }, [props.setthetags]);

  useEffect(() => {
    if (rows) {
      let rowssort = sortArray([...rows], { by: ['tagcategory','tagoptionname'], order: 'asc'})
      setTags(rowssort.map((row) => (row.tagoptionname)));
    }
  }, [rows]);

  if (loading) {
    return <p>loading...</p>;
  }

  if (loading2) {
    console.log("loading2");
    return <p>loading2...</p>;
  }

  if (error) {
    //console.log(error)
    return <p>Ruh roh! {error.message}</p>;
  }


  return (
    <div>

    { tags && (

      <Select 
        value={seltags}
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={() =>
                  setSelTags(
                    seltags.filter((item) => item !== value)
                  )
                }
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </Stack>
        )}
        onChange={handleChangeSelTags}
        sx={{ margin: 3, textAlign:"left", width:props.width }}
        multiple
        variant="standard"
      >
        {tags.map((option, index) => (
          <MenuItem key={index} value={option}>{option}</MenuItem>
        ))}
      </Select>

   )}

   </div>
  );
}
export default Taglistabbneche;
