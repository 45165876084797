import React, { useState, useEffect } from 'react';
import { Stack, Typography, ToggleButton, ToggleButtonGroup, Box } from "@mui/material";
import * as FlexmonsterReact from "react-flexmonster";
import GetUrl from "../components/GetUrl";
import { Getdata } from './Getdata';
import outcomes from "./outcomes.json";
import { useNavigate } from 'react-router-dom';


function Viewoutcomescrosstabs() {
  const [ gotolink, setGoToLink] = useState("/viewoutcomescrosstabs");


  const navigate = useNavigate();


  const handleChangeLink = (e, value) => {
    setGoToLink(value);
    navigate(value, { replace: true})
  }


  return (
    <div>

      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between', width: '700px', ml: 2, pb: 5 }}>
        <ToggleButtonGroup value={gotolink} color="primary" exclusive onChange={handleChangeLink}>
          <ToggleButton value="/viewenrollmentcounts">Enrollment</ToggleButton>
          <ToggleButton value="/viewoutcomescounts">Outcomes</ToggleButton>
          <ToggleButton value="/viewenrollmentcrosstabs">Enrollment Crosstabs</ToggleButton>
          <ToggleButton value="/viewoutcomescrosstabs">Outcomes Crosstabs</ToggleButton>
          <ToggleButton value="/viewbillablecredits">Billable Credits</ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h4" sx={{ ml: 2}}>Outcomes Crosstabs</Typography>
      </Stack>

      <Box sx={{ fontSize: 11, ml: 2, display:'flex', alignItems:'left' }}>For internal distribution only.</Box>      

      <FlexmonsterReact.Pivot
       licenseKey="Z70P-XI9F56-045Q66-1E0W0Q-6N5B20-4B6R34-044J6V-2T491Y-2B290W-0C6W02-423X1Z-4I6L53-5W1L"
       height={900}
       width={'95%'}
       align={"center"}
       toolbar={true}
       report={{
         dataSource: {
           data: outcomes
         },
         slice: {
           rows: [
             { uniqueName: "Degree_Start" },
           ],
           columns: [
             { uniqueName: "Grad_Ever" }
           ],
           measures: [
             { uniqueName: "personid", aggregation: "Count", caption: "Students" }
           ]
         },
         options: {
             grid: {
               title: "Outcomes for Degree Seekers as of 2/28/25"
             },
             chart: {
               title: "Outcomes for Degree Seekers as of 2/28/25"
             }
         }         
       }}
      />
    </div>
  );
}

export default Viewoutcomescrosstabs;