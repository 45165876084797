import React, { useState, useEffect, useContext } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useMutation, useQuery, gql } from "@apollo/client";
import { useSelector } from 'react-redux';
import Header from './Header'
import jwt_decode from "jwt-decode";
import { useDispatch } from 'react-redux';
import userSlice, { setuser, setlogintime, setorgunit } from './userSlice';
import Welcome from "./Welcome";
import Getorgunit from "./Getorgunit";
import { useNavigate } from 'react-router-dom';
//import { newuserStore } from "../userStore";
//import UserContext from "../UserContext";

const mutatequery = gql`
  mutation edituser($email: String, $lastlogin: Float, $avatar: String) {
    updateuserbyemail(email: $email, lastlogin: $lastlogin, avatar: $avatar) {
      email
      idnumber
      firstname
      lastname
      orgunit
      lastlogin
      avatar
    }
  }`
;

const GoogleLoginPage = () => {
  //let email = "michael.walker@collegeunbound.edu"
  const [ edituser, { data, loading, error } ] = useMutation(mutatequery);
  const clientId = "270790451414-6le54esosos3tl40d7i20g5o312conjr.apps.googleusercontent.com"
  const [success, setSuccess] = useState(false)
  const [doc, setDoc] = useState();
  const [ seluserinfo, setSelUserInfo] = useState(null);
  const [ foundemail, setFoundEmail ] = useState(null);
  const dispatch = useDispatch();
  //let pagedirect = localStorage.getItem("pagedirect");
  //const { logintime, setLogintime } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    console.log('success=', success);
  }, [success])

  useEffect(() => {
    console.log('foundemail=', foundemail);
  }, [foundemail])

  const userinfo = useSelector((state) => state.userinfo);
  const pagedirect = userinfo.pagedirect;
  //console.log('foundemail=', foundemail);

  const responseGoogle = (response) => {
    const userObject = jwt_decode(response.credential);
    sessionStorage.setItem('user', JSON.stringify(userObject));

    const { email, hd, picture } = userObject;
    let avatar = picture;
    setFoundEmail(email);
    console.log("email=", email);

    setDoc(userObject);

    if (hd==="collegeunbound.edu") {
      dispatch(setuser(userObject));
      dispatch(setlogintime(Date.now()));
      dispatch(setorgunit("Staff"));
      let lastlogin = Date.now();
      //sessionStorage.setItem('sesslog', true);
      localStorage.setItem('lastlogin', lastlogin);
      localStorage.setItem('email', email);
      localStorage.setItem('avatar', avatar);
      localStorage.setItem('user', JSON.stringify(userObject));

      //testlogin = userStore((state) => state.lastlogin);
      //userStore((state) => state.setLastlogin(10));
      //console.log("testlogin=", testlogin)
      //let newtext = userStore((state) => state.text);
      //console.log("newtext=", newtext);
      //uc.logintime = 10;
      //let uc2 = () => setLogintime(10);
      //console.log("logintime=", logintime);

      edituser({  variables: { email, lastlogin, avatar } });
      console.log("email=", email);
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  }

  useEffect(() => {
    console.log("pagedirect=", pagedirect)
    if (pagedirect && pagedirect.length>1 && foundemail) {
      console.log("pagedirect.length=", pagedirect.length);
      navigate(pagedirect, { replace: true});
      //localStorage.removeItem("pagedirect");
    }
  }, [pagedirect, foundemail]);


  return (
    <>
    <div className="">
          { !success && (
          <div align='center'>
            <GoogleOAuthProvider clientId={clientId}>
             <GoogleLogin
               onSuccess={responseGoogle}
               useOneTap
               onError={() => {
                 console.log('Login Failed');
               }}
             />
            </GoogleOAuthProvider>
          </div>

        )}
        {/* foundemail && (
          <Getorgunit email={foundemail} />
        )*/}
    </div>
      {!foundemail && (
        <div>
          <br/><br/>
          <h3>Welcome</h3>
          <div>Please login to view the College Unbound Community pages.</div>
        </div>
      )}
    </>
  )
}

export default GoogleLoginPage
